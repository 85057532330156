import styled from 'styled-components'

const NavbarNav = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`

export default NavbarNav
