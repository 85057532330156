import styled from 'styled-components'

const NavbarCollapse = styled.div`
  background-color: ${ props => props.theme.colors.darkgrey };

  flex: 0 0 100%;
  align-items: center;

  display: ${ props => props.show ? 'block' : 'none' };
  width: 100%;
  position: fixed;
  left: 0;
  z-index: 1030;
  -webkit-transition: top .15s ease;
  transition: top .15s ease;
  height: calc(100% - 64px);
  top: 64px;

  > ul {
    display: block;
    padding-top: 100px;
  }

  > ul li {
    text-align: center;
    font-size: 2.2em;
    font-weight: 300
  }

  > ul li a, > ul li span {
    background-color: ${ props => props.theme.colors.darkgrey };
    color: white;
    padding: 10px 5px;
    border-bottom: 3px solid ${ props => props.theme.colors.primary };
    display: inline-block;
    font-size: 1.1em;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
`

export default NavbarCollapse
