export default (coordinates = []) => {
  if (coordinates.length === 0) {
    return
  }

  const ne = {}
  const sw = {}

  coordinates.forEach(coord => {
    ne.lat = ne.lat && coord.lat > ne.lat ? ne.lat : coord.lat
    sw.lat = sw.lat && coord.lat < sw.lat ? sw.lat : coord.lat

    ne.lng = ne.lng && coord.lng > ne.lng ? ne.lng : coord.lng
    sw.lng = sw.lng && coord.lng < sw.lng ? sw.lng : coord.lng
  })

  return { northEast: ne, southWest: sw }
}
