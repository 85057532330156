import styled from 'styled-components'

import Brand from './Brand'
import Container from './Container'
import Menu from './Menu'

const Navbar = styled.div.attrs({
  role: 'navigation',
  'aria-label': 'Main navigation',
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1030;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 1em;
  border-bottom: 1px solid ${ props => props.theme.nav.borderColor };
  background-color: ${ props => props.theme.nav.backgroundColor };
  color: ${ props => props.theme.nav.bodyColor };
  ${ props => props.show ? 'height: 100%;' : '' }

  @media (min-width: 992px) {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
`

Navbar.Brand = Brand
Navbar.Container = Container
Navbar.Menu = Menu

export default Navbar
