import styled from 'styled-components'
import Link from '../../Link'

const NavbarBrand = styled(Link)`
  flex: 1 0 auto;
  display: inline-block;
  margin-right: 1em;
  white-space: nowrap;

  svg {
    height: 35px;
    margin: 14px 0;
  }
`

export default NavbarBrand
