import React from 'react'
import styled from 'styled-components'
import OutsideClickHandler from 'react-outside-click-handler'

import Link from './Link'

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`

const Dropdown = ({ onOutsideClick, children, ...props }) => {
  return <DropdownContainer {...props}>
    {onOutsideClick
      ? <OutsideClickHandler onOutsideClick={onOutsideClick}>
        {children}
      </OutsideClickHandler>
      : children}
  </DropdownContainer>
}

Dropdown.Toggle = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem .375rem .75rem;
  line-height: 1.5;
  color: ${ props => props.theme.dropdown.bodyColor };
  vertical-align: middle;
  background-size: 8px 10px;
  background-color: ${ props => props.theme.dropdown.backgroundColor };
  border: 1px solid ${ props => props.theme.dropdown.borderColor };
  border-radius: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-transform: none;
  text-align: left;
  transition:
    background-color .15s ease-in-out,
    border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;

  &::after {
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
`

Dropdown.Menu = styled.div.attrs({
  role: 'menu',
})`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 100%;
  ${ props => props.fullWidth ? 'width: 100%;' : '' }
  max-height: 250px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: .5rem 0;
  margin: .125rem 0 0;
  text-align: left;
  list-style: none;
  background-color: ${ props => props.theme.dropdown.backgroundColor };
  background-clip: padding-box;
  border: 1px solid ${ props => props.theme.dropdown.borderColor };
  border-radius: .25em;

  display: ${ props => props.show ? 'block' : 'none' };

  ${ props => props.right ? `
      right: 0;
      left: auto;
  ` : '' }
`

Dropdown.Item = styled(Link).attrs({
  role: 'menuitem',
})`
  display: block;
  width: 100%;
  padding: .25rem ${ props => (props.indent || 1) * 1.5 }em;
  clear: both;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
  font-size: .9em;

  span {
    display: block;
    font-size: .9em;
    font-style: italic;
  }

  &:hover {
    text-decoration: none;
    background-color: ${ props => props.theme.dropdown.hover.backgroundColor };
  }

  &[aria-current="page"], &.active {
    background-color: ${ props => props.theme.dropdown.active.backgroundColor };
    color: ${ props => props.theme.dropdown.active.bodyColor };
  }
`

export default Dropdown
