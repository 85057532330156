import styled from 'styled-components'

import { Container } from '../../Grid'

const NavbarContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 575.9px) {
    padding: 0
  }

  @media (min-width: 992px) {
    flex-wrap: nowrap;
  }
`

export default NavbarContainer
