import styled from 'styled-components'
import Link from '../../Link'

const NavLink = styled(Link)`
  position: relative;
  cursor: pointer;
  font-size: 1.2em;
  line-height: 1em;
  letter-spacing: 1px;
  display: block;
  padding: .5rem;
  text-decoration: none;
  border:
  ${ props => props.border && props.theme.nav.link.borderColor
    ? '1px solid ' + props.theme.nav.link.borderColor
    : 0 };
  color: ${ props => props.theme.nav.link.bodyColor };
  background-color: ${ props => props.theme.nav.link.backgroundColor };

  svg + * {
    display: none;
    margin-left: 10px;
  }

  svg * {
    fill: ${ props => props.theme.colors.primary };
  }

  &:hover {
    color: ${ props => props.theme.nav.link.active.bodyColor };
    text-decoration: none;
  }

  &::before {
    content: "";
    width: 0;
    height: 2px;
    background-color: ${ props => props.theme.nav.link.active.bodyColor };
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: -3px;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
  }

  @media (min-width: 576px) {
    svg + * {
      display: initial;
    }
  }
`

export default NavLink
