import { transparentize } from 'polished'

const defaults = {
  primary: '#2fa7d8',
  secondary: '#f9d649',
  darkgrey: '#333333',
  white: '#ffffff',
  black: '#000000',
}

export const getTheme = (colors = defaults) => ({
  colors,
  fonts: {
    header: 'Open Sans, sans-serif',
    body: 'Montserrat, sans-serif',
  },
  body: {
    backgroundColor: colors.white,
  },
  main: {
    bodyColor: colors.darkgrey,
  },
  well: {
    headerColor: colors.black,
    bodyColor: colors.darkgrey,
    linkColor: colors.black,
    backgroundColor: transparentize(0.9, colors.darkgrey),
  },
  slider: {
    backgroundColor: transparentize(0.9, colors.darkgrey),
    bodyColor: colors.darkgrey,
    progressBar: {
      backgroundColor: colors.primary,
    },
    button: {
      backgroundColor: colors.white,
      borderColor: transparentize(0.8, colors.darkgrey),
    },
  },
  buttonGroup: {
    default: {
      backgroundColor: colors.white,
      borderColor: transparentize(0.8, colors.darkgrey),
      bodyColor: colors.darkgrey,
    },
    active: {
      backgroundColor: colors.primary,
      borderColor: colors.primary,
      bodyColor: colors.white,
    },
    disabled: {
      backgroundColor: transparentize(0.9, colors.darkgrey),
      bodyColor: transparentize(0.6, colors.darkgrey),
    },
  },
  map: {
    backgroundColor: transparentize(0.9, colors.darkgrey),
    popup: {
      backgroundColor: colors.white,
    },
    apartment: {
      backgroundColor: colors.primary,
      active: {
        backgroundColor: colors.black,
      },
    },
    market: {
      backgroundColor: colors.secondary,
      bodyColor: colors.white,
    },
    toggle: {
      backgroundColor: colors.white,
      bodyColor: colors.darkgrey,
      borderColor: transparentize(0.8, colors.darkgrey),
    },
  },
  breadcrumb: {
    backgroundColor: colors.white,
    bodyColor: colors.darkgrey,
  },
  footer: {
    backgroundColor: colors.darkgrey,
    bodyColor: colors.white,
    headerColor: colors.primary,
    borderColor: colors.darkgrey,
    hoverColor: colors.primary,
  },
  nav: {
    backgroundColor: colors.white,
    borderColor: transparentize(0.8, colors.darkgrey),
    bodyColor: colors.white,
    link: {
      backgroundColor: colors.white,
      bodyColor: colors.black,
      borderColor: transparentize(0.8, colors.darkgrey),
      active: {
        bodyColor: colors.primary,
      },
    },
    locations: {
      backgroundColor: colors.white,
      bodyColor: colors.darkgrey,
      headerColor: colors.primary,
    },
  },
  link: {
    bodyColor: colors.darkgrey,
    hover: {
      bodyColor: colors.black,
    },
  },
  dropdown: {
    backgroundColor: colors.white,
    borderColor: transparentize(0.8, colors.darkgrey),
    bodyColor: colors.darkgrey,
    hover: {
      backgroundColor: transparentize(0.9, colors.darkgrey),
    },
    active: {
      backgroundColor: colors.primary,
      bodyColor: colors.white,
    }
  },
  card: {
    backgroundColor: colors.white,
    boxShadow: '0 0 2px 2px ' + transparentize(0.8, colors.darkgrey),
    border: {
      color: 'transparent',
      hover: {
        color: transparentize(0.8, colors.darkgrey),
      }
    },
    headerColor: colors.black,
    bodyColor: colors.darkgrey,
    button: {
      default: {
        backgroundColor: transparentize(0.9, colors.darkgrey),
        bodyColor: colors.darkgrey,
      },
      primary: {
        backgroundColor: colors.primary,
        bodyColor: colors.white,
      },
    },
    ad: {
      backgroundColor: colors.white,
      bodyColor: colors.black,
      boxShadow: '0 0 2px 2px ' + transparentize(0.8, colors.darkgrey),
    },
  },
})
