import React from 'react'
import styled from 'styled-components'

import * as Grid from '../Grid'

import Legal, { Logo, Icons } from './Legal'
import Menus from './Menus'
import Menu from './Menu'

const Footer = styled.div.attrs({
  role: 'contentinfo',
})`
  background-color: ${ props => props.theme.footer.backgroundColor };
  border-top: 1px solid ${ props => props.theme.footer.borderColor };
  color: ${ props => props.theme.footer.bodyColor };
  padding: 30px 0;
`

const Col = styled(Grid.Col)`
  display: flex;
  flex-direction: column;

  width: 100%;

  @media (min-width: 768px) {
    flex: 0 0 ${ props => props.condensed ? '50%' : '100%' };
    max-width: ${ props => props.condensed ? '50%' : '100%' };
  }

  @media (min-width: 992px)  {
    margin-left: -15px;
    flex: 0 0 ${ props => props.condensed ? '66.666667%' : '100%' };
    max-width: ${ props => props.condensed ? '66.666667%' : '100%' };
  }

  ${ Logo } {
    display: none;
  }

  ${ Icons }, ${ Menu }, > * {
    text-align: center;
    justify-content: center;
  }

  @media (min-width: ${ props => props.condensed ? '1200px' : '768px' }) {
    ${ Logo } {
      display: block;
    }

    ${ Icons }, ${ Menu }, > * {
      text-align: left;
      justify-content: flex-start;
    }

    flex-direction: row;
    justify-content: space-between;

    > *:last-child {
      justify-content: end;
    }
  }
`

const FooterContainer = styled(Grid.Container)`
  @media (min-width: 768px) and (max-width: 992px) {
    ${ props => props.condensed ? 'max-width: 100%;' : '' }
  }
`

export default ({ condensed }) =>
  <Footer>
    <FooterContainer condensed={condensed}>
      <Col condensed={condensed}>
        <Legal />
        <Menus />
      </Col>
    </FooterContainer>
  </Footer>
