import React from 'react'
import Helmet from 'react-helmet'

import icon from '../images/icon.png'

import { getSchemaOrgJSONLD } from '../utils'

const getMeta = ({
  account = {},
  breadcrumb = {},
  description,
  termGroup = {},
}) => [
  { charset: 'utf-8' },
  { name: 'description', content: description },
  { name: 'format-detection', content: 'telephone=no' },
  { 'http-equiv': 'x-ua-compatible', content: 'ie=edge' },
  { name: 'term-group', content: termGroup.name },
  { name: 'term-group-category', content: termGroup.category },
  {
    name: 'viewport',
    content: 'width=device-width, initial-scale=1, maximum-scale=2',
  },
  {
    name: 'google-site-verification',
    content: account.googleSiteVerification,
  },
  {
    name: 'market',
    content: breadcrumb.market ? breadcrumb.market.title : '',
  },
  {
    name: 'submarket',
    content: breadcrumb.submarket ? breadcrumb.submarket.title : '',
  },
]

const Head = ({
  account,
  title,
  description,
  noindex,
  breadcrumb,
  apartments,
  termGroup,
}) =>
  <Helmet>
    <html lang='en' />
    {getMeta({ account, description, breadcrumb, termGroup }).map((props, i) =>
      <meta key={i} {...props} />
    )}
    <title>{ title ? title + ' | ' : '' }{account.title || account.name}</title>
    <link href={icon} rel='shortcut icon' type='image/x-icon' />
    {noindex ? <meta name='robots' content='noindex,nofollow' /> : null}
    <script type='application/ld+json'>
      {JSON.stringify(getSchemaOrgJSONLD(breadcrumb, apartments))}
    </script>
    <script type='text/javascript'>
      {'var _userway_config = { account: \'zhaXh8kyO0\' }'}
    </script>
    <script type='text/javascript'
      src='https://cdn.userway.org/widget.js'>
    </script>
  </Helmet>

export default Head
