import React from 'react'
import styled from 'styled-components'

import NavItem from './NavItem'
import NavLink from './NavLink'
import Dropdown from '../../Dropdown'

const CustomDropdownMenu = styled(Dropdown.Menu)`
  @media (max-width: 768px) {
    border: 0;
    position: relative;
  }
`

export default ({ show, onOutsideClick, onClick, menuItem, subMenu }) =>
  <li>
    <Dropdown onOutsideClick={onOutsideClick}>
      <NavItem as='div'>
        {/*  TODO: add dropdown-toggle (see .dropdown-toggle::after) */}
        <NavLink as='button'
          id={'nav-dropdown-' + menuItem.title}
          aria-expanded={show}
          aria-haspopup={true}
          onClick={onClick}
        >
          {menuItem.title}
        </NavLink>
      </NavItem>
      <CustomDropdownMenu show={show} right
        aria-labelledby={'nav-dropdown-' + menuItem.title}
      >
        {subMenu.map((subMenuItem, i) =>
          <Dropdown.Item key={i} href={subMenuItem.href}>
            {subMenuItem.title}
            {subMenuItem.subTitle
              ? <span>{subMenuItem.subTitle}</span>
              : null
            }
          </Dropdown.Item>
        )}
      </CustomDropdownMenu>
    </Dropdown>
  </li>
