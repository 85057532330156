import styled from 'styled-components'

const NavItem = styled.li`
  margin-bottom: 0;
  padding: .5em 0;

  display: ${ props => props.mobile === false ? 'none' : 'list-item' };

  @media (min-width: 992px) {
    display: list-item;
    padding: .5em;
  }
`

export default NavItem
