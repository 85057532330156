import styled from 'styled-components'

const Col = styled.div`
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  flex: ${ props => props.flex || '0 0 100%' };
  max-width: 100%;
`

export default Col
