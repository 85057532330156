import React from 'react'
import styled from 'styled-components'

import { ReactComponent as NoSmoking } from '../../images/faSmokingBan.svg'
import { ReactComponent as PetFriendly } from '../../images/faPaw.svg'
import { ReactComponent as Wheelchair } from '../../images/faWheelchair.svg'

import { ReactComponent } from '../../images/icon.svg'
import { ReactComponent as FairHousing } from '../../images/fair-housing.svg'

import Menu from './Menu'
import Link from '../Link'

export const Logo = styled(ReactComponent)`
  width: 32px;
  height: 32px;
  margin-right: 10px;
`

export const Icons = styled.div`
  display: flex;
  align-items: center;

  svg * {
    fill: ${ props => props.theme.footer.bodyColor };
  }

  & > * {
    margin: 0 2.5px
  }

  & > *:first-child {
    margin-left: 0;
  }
  & > *:last-child {
    margin-right: 0;
  }
`

const Div = styled.div`
  display: flex;
  margin-bottom: 10px;

  > *:last-child {
    flex: 1 0 auto;
  }
`

export default () => <Div>
  <Link href='https://www.lineups.io/'><Logo /></Link>
  <div>
    <Menu>
      <Menu.SubTitle copyright>© 2019 LINEUPS, INC.</Menu.SubTitle>
      <div>
        <Menu.Item href='privacy'>PRIVACY POLICY</Menu.Item>
        <Menu.Item href='website-disclaimer'>WEBSITE DISCLAIMER</Menu.Item>
      </div>
    </Menu>
    <Icons>
      <FairHousing height={16} width={19} />
      <NoSmoking height={16} width={16} />
      <PetFriendly height={16} width={16} />
      <Wheelchair height={16} width={16} />
    </Icons>
  </div>
</Div>
