import React, { useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'

import { useMixpanel } from 'gatsby-plugin-mixpanel'

import { PageContext, mapKeys, getTheme } from '../utils'

import Helmet from './Helmet'
import Nav from './Nav'
import Footer from './Footer'

const ScrollContainer = styled.div`
  height: 100%;
  padding-top: 64px;
  display: flex;
  flex-direction: column;
`

const Main = styled.div`
  flex: 1 0 auto;
  background-color: ${ props => props.theme.body.backgroundColor };
`

const Layout = ({
  account,
  page,
  logo,
  menu,
  markets,
  relatedPages,
  children,
  condensedFooter = false,
}) => {
  const mixpanel = useMixpanel()
  const {
    noindex,
    utm,
    termGroup,
    breadcrumb: { market, submarket } = {},
  } = page

  const { location } = typeof window !== 'undefined' ? window : {}
  const context = {
    ...location,
    noindex,
    utm,
    termGroup: termGroup && termGroup.name,
    termGroupCategory: termGroup && termGroup.category,
    market: market && market.title,
    submarket: submarket && submarket.title,
    state: market && market.state.name,
  }

  useEffect(() => {
    mixpanel.track('Page View', context)

    const { apartments } = page
    if (apartments) {
      apartments.items.forEach(apartment => {
        const {
          name,
          address,
          adLabel,
          marketingWebsiteUrl,
        } = apartment

        const event = {
          name,
          ...mapKeys(address, (v, k) => 'address.' + k),
          adLabel,
          marketingWebsiteUrl,
          ...context,
        }

        mixpanel.track('Apartment View', event)
      })
    }
  })

  return <ThemeProvider theme={getTheme()}>
    <>
      <Helmet account={account} {...page} />
      <PageContext.Provider value={context}>
        <Nav logo={logo} menu={menu} markets={markets} />
        <ScrollContainer>
          <Main>{children}</Main>
          <Footer condensed={condensedFooter} />
        </ScrollContainer>
      </PageContext.Provider>
    </>
  </ThemeProvider>
}

export default Layout
