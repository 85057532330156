import React from 'react'
import styled from 'styled-components'

import Menu from './Menu'

const menus = [
  {
    label: 'Powered By',
    items: [
      {
        href: 'https://www.lineups.io',
        children: 'lineups.io',
      },
    ],
  },
]

const Div = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  & > * {
    margin: 0 7.5px
  }

  & > *:first-child {
    margin-left: 0;
  }
  & > *:last-child {
    margin-right: 0;
  }
`

export default () => <Div>
  {menus.map((menu, m) => menu.items.length > 0
    ? <Menu key={m}>
      <Menu.SubTitle>{menu.label}</Menu.SubTitle>
      <div>
        {menu.items.map((item, i) =>
          <Menu.Item key={i} {...item} />
        )}
      </div>
    </Menu> : null
  )}
</Div>
