import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { useMixpanel } from 'gatsby-plugin-mixpanel'

import { usePageContext } from '../utils'

const Span = styled.span`
  color: ${ props => props.theme.link.bodyColor };
  border: 0;
  background-color: transparent;
  padding: 0;

  &[href] {
    cursor: pointer;
  }

  &[href]:hover {
    text-decoration: underline;
    color: ${ props => props.theme.link.hover.bodyColor };
  }
`

const Component = ({ children, href, tracking = {}, ...props }) => {
  if (!href) {
    return <Span {...props} tabIndex='-1'>{children}</Span>
  } else if (href.match(/^(https?|tel|mailto):/)) {
    props.rel = `${ props.rel || '' } noopener`
    props.target = '_blank'

    const page = usePageContext()
    const {
      noindex,
      utm,
      termGroup = '',
      market,
      submarket,
      state,
    } = page

    const query = {
      'utm_source': 'lineups',
      'utm_medium': noindex ? 'cpc' : 'seo',
      'utm_campaign': `${ submarket || market || '' } ${ state || '' }`
        .trim().toLowerCase().replace(/[^a-z0-9]+/g, '_'),
      'utm_term': termGroup.toLowerCase().replace(/ +/g, '+'),
      ...utm,
    }
    const queryString = href.match(/^http/)
      ? ((href.match(/\?/) ? '&' : '?') +
        Object.keys(query).map(key => key + '=' + query[key]).join('&'))
      : ''

    const mixpanel = useMixpanel()
    const {
      category = 'Outbound Link',
      action = 'Click',
      label = href,
      ...eventProps
    } = tracking
    const event = { href, ...eventProps, ...page }
    const onClick = e => {
      props.onClick && props.onClick(e)
      mixpanel.track(`${ category } ${ action }`, event)
      const trackers = window.ga && window.ga.getAll()
      if (trackers) {
        trackers.forEach(tracker =>
          tracker.send('event', category, action, label)
        )
      }
    }

    return <a href={href + queryString} {...props} onClick={onClick}>
      {children}
    </a>
  } else if (href.match(/^#/)) {
    return <Link to={href} {...props}>
      {children}
    </Link>
  } else {
    const split = href.split('?')
    props.to = split[0].replace(/^\/?/, '/').replace(/\/?$/, '/') +
      (split[1] ? `?${ split[1] }` : '')
    return <Link {...props}>
      {children}
    </Link>
  }
}

export default Component
