import React, { useState } from 'react'

import Navbar from './Navbar'
import { ReactComponent as Logo } from '../../images/logo.svg'

const Header = ({ menu, markets }) => {
  const [show, setShow] = useState(false)
  const [showMenu, setShowMenu] = useState(false)

  const menuProps = {
    menu,
    markets,
    show,
    showMenu,
    toggleMenu: () => {
      setShowMenu(!showMenu)
      setShow(false)
    },
    toggleDropdown: i => setShow(show === i ? false : i),
    closeDropdown: i => show === i && setShow(false),
  }

  const handleKeyDown = e => {
    if (e.key === 'Escape') {
      show ? setShow(false) : setShowMenu(false)
    }
  }

  return <Navbar show={showMenu} onKeyDown={handleKeyDown}>
    <Navbar.Container>
      <Navbar.Brand
        href='/'
        aria-label='Logo'
      >
        <Logo />
      </Navbar.Brand>
      <Navbar.Menu {...menuProps} />
    </Navbar.Container>
  </Navbar>
}

export default Header
