export default (callback, wait, immediate = false) => {
  let timeout = null

  return (...args) => {
    const callNow = immediate && !timeout
    const next = () => callback.apply(this, args)

    clearTimeout(timeout)
    timeout = setTimeout(next, wait)

    if (callNow) {
      next()
    }
  }
}
