import styled from 'styled-components'

import Link from '../Link'

const Menu = styled.div`
  padding-bottom: 5px;
`

Menu.SubTitle = styled.div`
  font-family: ${ props => props.copyright
    ? props.theme.fonts.body : props.theme.fonts.header };
  color: ${ props => props.copyright
    ? props.theme.footer.bodyColor : props.theme.footer.headerColor };
  font-size: 1em;
`

Menu.Item = styled(Link)`
  font-family: ${ props => props.theme.fonts.body };
  color: ${ props => props.theme.footer.bodyColor };
  font-size: .9em;

  &:hover {
    color: ${ props => props.theme.footer.hoverColor };
  }

  * > & {
    margin: 0 5px
  }

  * > &:first-child {
    margin-left: 0;
  }
  * > &:last-child {
    margin-right: 0;
  }
`

export default Menu
