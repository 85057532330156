import React from 'react'

import { ReactComponent as Bars } from '../../../images/faBars.svg'

import Nav from './Nav'
import NavItem from './NavItem'
import NavLink from './NavLink'
import Dropdown from './Dropdown'
import Collapse from './Collapse'

const Menu = ({
  menu = [],
  markets,
  show,
  showMenu,
  toggleMenu,
  toggleDropdown,
  closeDropdown,
}) =>
  <>
    <Nav>
      <NavItem>
        <NavLink as='button' border
          aria-controls='navbar-menu'
          aria-expanded={showMenu}
          aria-label='Toggle navigation'
          open={showMenu}
          onClick={toggleMenu}
        >
          <Bars height={16} width={18} />
          <span>Menu</span>
        </NavLink>
      </NavItem>
    </Nav>
    <Collapse id='navbar-menu' show={showMenu}>
      <Nav>
        {menu.map(({ subMenu = [], ...menuItem }, i) =>
          subMenu && subMenu.length
            ? <Dropdown key={i}
              show={show === i}
              onOutsideClick={() => closeDropdown(i)}
              onClick={() => toggleDropdown(i)}
              menuItem={menuItem}
              subMenu={subMenu}
            />
            : <NavItem key={i} onClick={toggleMenu}>
              <NavLink href={menuItem.href}>
                {menuItem.title}
              </NavLink>
            </NavItem>
        )}
      </Nav>
    </Collapse>
  </>

export default Menu
