export default (price, suffix = '+') => {
  let [n, d = ''] = price.toString().split('.')

  n = n.replace(/\D/g, '')
  d = d.replace(/\D/g, '')

  let str = ''

  while (n.length > 0) {
    str = ',' + n.slice(-3) + str
    n = n.replace(/.{1,3}$/, '')
  }

  return '$' + str.replace(/^,/, '') + (d ? '.' + d : '') + suffix
}
